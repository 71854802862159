<template>
  <b-row>
    <b-col class="col-12 col-lg-6">
      <b-card no-body :class="is_detail_network ? 'border-box-analytics' : ''">
        <b-card-header>
          <div>
            <b-card-title><h1>{{ followersCount }}</h1></b-card-title>
            <b-card-text>{{$t('instagram.followers')}}</b-card-text>
            <b-link v-if="!is_detail_network" class="alert-link" :href="`https://instagram.com/${username}`" target="_blank">{{$t('instagram.visitProfile')}}</b-link>
          </div>
        </b-card-header>
        <hr/>
        <b-row class="avg-sessions p-1">
          <b-col cols="12" class="mb-2">
            <b-card-text class="mb-50">{{$t('instagram.healthyAudience')}} <strong>{{influenceLevel}}%</strong></b-card-text>
            <b-progress :value="influenceLevel" max="100" height="6px" :variant="getColor(influenceLevel)"></b-progress>
          </b-col>
        </b-row>
        <b-row class="avg-sessions p-1">
          <b-col cols="12" class="mb-2">
            <b-card-text class="mb-50">{{$t("instagram.qualityAudience")}}: <strong>{{aqsFormated}}</strong></b-card-text>
            <b-progress :value="aqs" max="100" height="6px" :variant="getColor(aqs)"></b-progress>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col class="col-12 col-lg-6">
      <statistic-card-with-line-chart
          icon="UsersIcon"
          :statistic="$t(`instagram.${mainData.audience_reachability.title}`)"
          :statistic-title="$t('instagram.audienceScope')"
          :statisticText="reacheabilityText"
          :chart-data="randomSeries"
          :color="getColor(mainData.audience_reachability.title)"
          :heightChart="160"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
    </b-col>
    <b-col class="col-12 col-xl-4 col-lg-6">
      <b-row>
        <b-col cols="12">
          <statistic-card-horizontal
          icon="UsersIcon"
          :color="getColor(mainData.audience_authenticity.title)"
          :statistic="getTitle(mainData.audience_authenticity)"
          :statistic-title="$t('instagram.audienceAuth')"
          v-b-tooltip.hover.v-primary.bottom="authenticityDesc"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
        </b-col>
        <b-col cols="12">
          <statistic-card-horizontal
          icon="MessageIcon"
          :color="getColor(mainData.comments_rate.title)"
          :statistic="getTitle(mainData.comments_rate)"
          :statistic-title="$t('instagram.feedbackRate')"
          v-b-tooltip.hover.v-primary.bottom="tooltipRate"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
        </b-col>
      </b-row>
    </b-col>
    <b-col class="col-12 col-xl-4 col-lg-6">
      <b-row>
        <b-col cols="12">
          <statistic-card-horizontal
          icon="UsersIcon"
          :color="getColor(mainData.likes_comments_ratio.title)"
          :statistic="getTitle(mainData.likes_comments_ratio)"
          :statistic-title="$t('instagram.likeAndComments')"
          v-b-tooltip.hover.v-primary.bottom="tootipRatio"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
        </b-col>
        <b-col cols="12">
          <statistic-card-horizontal
          icon="MessageIcon"
          :color="getColor(mainData.likes_spread.title)"
          :statistic="getTitle(mainData.likes_spread)"
          :statistic-title="$t('instagram.likeProp')"
          v-b-tooltip.hover.v-primary.bottom="tooltipLike"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
        </b-col>
      </b-row>
    </b-col>
    <b-col class="col-12 col-xl-4">
      <b-row>
        <b-col cols="12">
          <statistic-card-horizontal
          icon="UsersIcon"
          :color="getColor(mainData.growth.title)"
          :statistic="getTitleIncrease()"
          :statistic-title="$t('instagram.increaseFollowers')"
          v-b-tooltip.hover.bottom="followersHigh"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
        </b-col>
        <b-col cols="12">
          <statistic-card-horizontal
          icon="MessageIcon"
          :color="getColor(mainData.yearly_growth.title)"
          :statistic="getTitle(mainData.yearly_growth)"
          :statistic-title="$t('instagram.anualGrowth')"
          v-b-tooltip.hover.bottom="followingHigh"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
        </b-col>
      </b-row>
    </b-col>
    <b-col class="col-12 col-xl-4 col-lg-6" v-if="mainData.advertising_data && mainData.advertising_data.avg_er_display">
      <statistic-card-with-line-chart
          icon="UsersIcon"
          :statistic="$t(`instagram.${mainData.advertising_data.avg_er_display[1]}`)"
          :statistic-title="$t('instagram.adPerformance')"
          :statisticText="adRateText"
          :chart-data="[{data: [3,2,4,3]}]"
          :color="getColor(80)"
          :heightChart="110"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
    </b-col>
    <b-col class="col-12 col-xl-4 col-lg-6" v-if="mainData.advertising_data && mainData.advertising_data.avg_er_display">
      <statistic-card-with-line-chart
          icon="UsersIcon"
          :statistic="$t(`${mainData.advertising_data.all_posts.display[1]}`)"
          :statistic-title="$t('instagram.publicationFreq')"
          :statisticText="postText"
          :chart-data="[{data: [4,2.5,3,4]}]"
          :color="getColor(80)"
          :heightChart="130"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
    </b-col>
    <b-col class="col-12 col-xl-4 col-lg-6" v-if="mainData.advertising_data && mainData.advertising_data.avg_er_display">
      <statistic-card-with-line-chart
          icon="UsersIcon"
          :statistic="$t(`instagram.${mainData.advertising_data.ad_posts.display[1]}`)"
          :statistic-title="$t(`Commercial publication frequency`)"
          :statisticText="adPostText"
          :chart-data="[{data: [3,2,4,3]}]"
          :color="getColor(80)"
          :heightChart="110"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
    </b-col>
  </b-row>
</template>
<script>
import {BRow, BCol, BCard, BProgress, BCardText, BCardTitle, BCardHeader, VBTooltip, BLink} from 'bootstrap-vue'
import { numberFormat, getFormat } from '@/libs/utils/formats' 

export default {
  components: {
    BRow,
    BCol,
    BProgress,
    BCard,
    BCardText,
    BCardTitle,
    BCardHeader,
    BLink,
    StatisticCardHorizontal: () => import('@core/components/statistics-cards/StatisticCardHorizontal.vue'),
    StatisticCardWithLineChart: () => import('@core/components/statistics-cards/StatisticCardWithLineChart.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    mainData: {
      type: Object,
      required: true
    },
    is_detail_network: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    username() {
      return this.$route.params.alias;
    },
    tooltipRate() {
      return `${this.$t('instagram.receiveFeed')} ${numberFormat(this.mainData.comments_rate.value)}% ${this.$t('instagram.ofAudience')}.`
    },
    tootipRatio() {
      return `${this.$t('instagram.receive')} ${numberFormat(this.mainData.likes_comments_ratio.value)} ${this.$t('instagram.commentEach')}`
    },
    tooltipLike() {
      return `${this.$t('instagram.distribution')} ${numberFormat(this.mainData.likes_spread.value * 100)}%`
    },
    followersCount() {
      return getFormat(Math.round(this.mainData.followers_count))
    },
    influenceLevel() {
      return Math.round(this.mainData.audience_reachability.value)
    },
    aqs() {
      const val = this.mainData.audience_type['infs'] + this.mainData.audience_type['real']
      if (val > 0) return Math.round(val)
      return 'No hay datos'
    },
    aqsFormated() {
      const val = this.mainData.followers_count * this.aqs / 100
      if (this.aqs !== 'No hay datos') return getFormat(val)
      return this.aqs
    },
    reacheabilityText() {
      return `${Math.round(this.mainData.audience_reachability.value)}% ${this.$t('instagram.compared')} ${Math.round(this.mainData.audience_reachability.avg)}% ${this.$t('instagram.ofOtherAccounts')}`
    },
    randomSeries() {
      return [{data: [4, 3, 2, 5]}]
    },
    adRateText() {
      return `${this.$t('instagram.er')}  ${numberFormat(this.mainData.advertising_data.avg_er)}%. ${this.$t('instagram.er2')}  ${numberFormat(this.mainData.advertising_data.avg_ad_er)}%`
    },
    postText() {
      return `${this.$t('instagram.realizes')} ${this.mainData.advertising_data.all_posts.count} ${this.$t('instagram.postWeek')}`
    },
    adPostText() {
      return `${this.$t('instagram.realizes')} ${this.mainData.advertising_data.ad_posts.count} ${this.$t('instagram.postTrade')}`
    },
    authenticityDesc() {
      return `${this.$t('instagram.the')} ${numberFormat(this.mainData.audience_authenticity.value)}% ${this.$t('instagram.seems')} ${numberFormat(this.mainData.audience_authenticity.avg)}% ${this.$t('instagram.authAudience')}`
    },
    followersHigh() {
      if (this.mainData.growth.title === 'Organic') return `${this.$t('instagram.noAbnormal')}`
      return `${this.$t('instagram.abnormal')}`
    },
    followingHigh() {
      if (this.mainData.yearly_growth.title === 'Excellent') return `${this.$t('instagram.noTracking')}`
      return `${this.$t('instagram.tracking')}`
    }
  },
  methods: {
    getTitleIncrease() {
      const title = this.mainData.growth.title.replace('.', ',').trim()
      return this.$t(`instagram.${title}`)
    },
    getColor (level) {
      if (level === 'Excellent' || level === 'Very Good' || level === 'High') return 'success'
      if (level === 'Good') return 'info'
      if (level === 'Average' || level === 'Poor' || level === 'Low') return 'warning'
      if (level >= 70) return 'success'
      if (level <= 30) return 'danger'
      if (level > 30) return 'warning'
      return 'primary'
    },
    getTitle (obj) {
      const number = obj.value ? obj.value : obj.avg;
      return `${numberFormat(number)}% ${this.$t(`instagram.${obj.title}`)}`
    },
    translateContent(content) {
      return content
    },
  }
}
</script>
